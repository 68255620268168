import { Challenge, CreateChallengeRequest, PaginatedChallengeResponse, PaginatedParticipantResponse, Participant, UpdateChallengeRequest } from "../models";
import { apiClient, convertToFormData, toQueryParams, transformDates } from "./api";

export const fetchChallenges = async (params?: Record<string, any>): Promise<PaginatedChallengeResponse> => {
    const url = (() => {
        const baseUrl = "/challenges";
        return params ? `${baseUrl}?${toQueryParams(params)}` : baseUrl;

    })();

    try {
        const { camelizedResponse } = await apiClient(url, {
            method: "GET",
        });

        if ("data" in camelizedResponse && Array.isArray(camelizedResponse.data)) {
            camelizedResponse.data = camelizedResponse.data.map((item: Challenge) => transformDates(item));
        }

        return camelizedResponse as PaginatedChallengeResponse;
    } catch (error) {
        console.error("Error fetching challenges: ", error);
        throw error;
    }
};

export const fetchChallenge = async (challengeId: number): Promise<Challenge> => {
    try {
        const { camelizedResponse } = await apiClient(`/challenges/${challengeId}`, {
            method: "GET",
        });

        return transformDates<Challenge>(camelizedResponse.data);
    } catch (error) {
        console.error(`Error fetching challenge ${challengeId}: `, error);
        throw error;
    }
};

export const postChallenge = async (challenge: CreateChallengeRequest): Promise<any> => {
    try {
        const { camelizedResponse } = await apiClient("/challenges", {
            method: "POST",
            body: convertToFormData(challenge),
        });

        return camelizedResponse;
    } catch (error) {
        console.error("Error creating challenge: ", error);
        throw error;
    }
};

export const putChallenge = async (challenge: UpdateChallengeRequest): Promise<any> => {
    try {
        const formData = convertToFormData(challenge);

        formData.append(`_method`, "PUT");

        const { camelizedResponse } = await apiClient(`/challenges/${challenge.id}`, {
            method: "POST",
            body: formData,
        });
        return camelizedResponse;
    } catch (error) {
        console.error("Error updating challenge: ", error);
        throw error;
    }
};

export const deleteChallenges = async (challengeIds: number[]): Promise<any> => {
    try {
        const { camelizedResponse } = await apiClient(`/challenges/delete`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                challenge_ids: challengeIds,
            }),
        });

        return camelizedResponse;
    } catch (error) {
        console.error("Error deleting challenges: ", error);
        throw error;
    }
};

export const fetchParticipants = async (challengeId: number, page?: number, perPage?: number): Promise<PaginatedParticipantResponse> => {
    try {
        const url = (() => {
            const baseUrl = `/challenges/${challengeId}/participants`;
            const params: Record<string, any> = { page: (page ?? 0) + 1, per_page: perPage };
            const queryString = toQueryParams(params);

            return queryString ? `${baseUrl}?${queryString}` : baseUrl;
        })();

        const { camelizedResponse } = await apiClient(url, {
            method: "GET",
        });

        if ("data" in camelizedResponse && Array.isArray(camelizedResponse.data)) {
            camelizedResponse.data = camelizedResponse.data.map((item: Participant) => transformDates(item));
        }

        return camelizedResponse as PaginatedParticipantResponse;
    } catch (error) {
        console.error(`Error fetching participants of challenge ${challengeId}: `, error);
        throw error;
    }
};
